import type { ReactElement } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { appRoutes } from 'config'
import classNames from 'classnames'

type Props = {
  isTransparent?:  boolean
}

const Footer = ({ isTransparent = false }: Props): ReactElement => {
  const { pathname } = useRouter()
  const isPrivacyPolicy = pathname === '/privacy-policy';
  const isTermsOfService = pathname === '/terms-of-service';
  const isHelpCentre = pathname === '/help-centre';

  const footerClassName = classNames(
    isTransparent
      ? ' bg-transparent border border-gray-300'
      : ' bg-white border border-gray-200',
    'w-100 h-24 flex justify-center items-center border-b-0 border-r-0 border-l-0'
  );

  const privacyPolicyClassName = classNames(
    isPrivacyPolicy
      ? 'border-mf-blue text-mf-blue'
      : (isTransparent ? 'border-transparent text-white hover:border-white hover:text-white' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 font-bold'),
    'inline-flex items-center px-1 pt-1 border-b-2 text-sm'
  );

  const termsOfServiceClassName = classNames(
    isTermsOfService
      ? 'border-mf-blue text-mf-blue'
      : (isTransparent ? 'border-transparent text-white hover:border-white hover:text-white' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 font-bold'),
    'inline-flex items-center px-1 pt-1 border-b-2 text-sm'
  );

  const copyrightClassName = classNames(
    isTransparent
      ? 'text-white'
      : 'text-black',
      'flex justify-center items-center grow'
  )

  const helpCentreClassName = classNames(
    isHelpCentre
      ? 'border-mf-blue text-mf-blue'
      : (isTransparent ? 'border-transparent text-white hover:border-white hover:text-white' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 font-bold'),
    'inline-flex items-center px-1 pt-1 border-b-2 text-sm'
  );

  return (
    <footer className={footerClassName}>
      <div className="text-center">
        <a
          href={appRoutes.HOME}
          className={copyrightClassName}
          target="_blank"
          rel="noopener noreferrer"
        >
          Copyright &#169;2022 by MindFeed. All rights reserved.
        </a>
        <div>
          <Link href={appRoutes.PRIVACY_POLICY} className={privacyPolicyClassName}>
            Privacy Policy
          </Link>
          <span className="text-gray-500"> | </span>
          <Link href={appRoutes.TERMS_OF_SERVICE} className={termsOfServiceClassName}>
            Terms Of Service
          </Link>
          <span className="text-gray-500"> | </span>
          <Link href={appRoutes.HELP_CENTRE} className={helpCentreClassName}>
            Help Centre
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
